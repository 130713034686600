<template>
    <loader v-bind="{ loading }">
        <columns class="is-centered">
            <column class="is-6">
                <h1 class="title is-3 has-text-centered">Account Setup</h1>
                <div class="box p-6">
                    <form>
                        <columns>
                            <column>
                                <text-input :value="account.company_name" :error="$root.errors.company_name"
                                    @input="updateCompanyName" classes="is-medium is-rounded">
                                    Company Name
                                </text-input>
                            </column>
                            <column>
                                <data-selector classes="is-medium is-rounded" searchable :value="account.industry_id"
                                    @input="updateIndustry" :items="industry_list" value-key="id" label-key="name">
                                    Industry
                                </data-selector>
                            </column>
                        </columns>
                        <columns>
                            <column>
                                <physical-address />
                            </column>
                        </columns>
                        <columns>
                            <column class="has-text-centered">
                                <submit-button @submit="setupAccount" class="is-medium is-rounded" right-icon="arrow-right">
                                    Next
                                </submit-button>
                            </column>
                        </columns>
                    </form>
                </div>
            </column>
        </columns>
    </loader>
</template>
<script>
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true
    }),

    async beforeCreate() {
        await this.$store.dispatch('industry/loadList')
        this.loading = false
    },

    methods: {
        setupAccount() {
            this.$store.dispatch('auth/updateAccount', this.address).then(() => {
                this.$store.dispatch('billing/loadSubscription').then(() => {
                    this.$router.push({
                        name: 'dashboard'
                    })
                })
            })
        },
        updateCompanyName(name) {
            this.$store.commit('auth/updateCompanyName', name)
        },
        updateIndustry(industry) {
            this.$store.commit('auth/updateIndustry', industry)
        }
    },

    computed: {
        ...mapGetters('auth', [
            'account'
        ]),
        ...mapGetters('location', [
            'location'
        ]),
        ...mapGetters('industry', [
            'industry_list'
        ]),
        address() {
            return this.location.address
        }
    }

}
</script>